import React, { useState, useEffect } from "react";
import styles from "./Languages.module.css";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { settlementActions } from "@_actions/settlement_portal.actions";
import { languageActions } from "@_actions/language.actions";
import SettlementPortalTexts from "@_components/SettlementPortalTexts/SettlementPortalTexts";
import TranslationRow from "@_components/TranslationRow/TranslationRow";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup.js";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import subscriptionCopy from "@_configs/subscription.config.js";
import UpgradeImage from "@_assets/images/add_translation.png";
import Scrollbar from "react-perfect-scrollbar-z";

function Languages() {
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const jwtData = getUserDataFromJwt();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [openLanguagePopup, setOpenLanguagePopup] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [displayUpgrade, setDisplayUpgrade] = useState(false);
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);

  const { title, subtitle, copy_1, upgradeButton } =
    subscriptionCopy.translation[jwtData.subscription.toLowerCase()];

  const handleOpenLanguagePopup = (e) => {
    if (
      jwtData.subscription.toLowerCase() !== "enterprise" &&
      languageReducer.translations &&
      languageReducer.translations.translations.length >=
        subscriptionReducer.subscriptionConfig.max_translations
    ) {
      setDisplayUpgrade(true);
    } else {
      setOpenLanguagePopup(true);
    }
  };

  const handleCloseLanguagePopup = () => {
    setOpenLanguagePopup(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenLanguagePopup(false);
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  useEffect(() => {
    dispatch(settlementActions.getSettlement());
  }, [dispatch]);

  useEffect(() => {
    dispatch(languageActions.getLanguages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(languageActions.getTranslations());
  }, [dispatch]);

  const handleSelectLanguage = (language) => {
    dispatch(languageActions.addTranslation({ language }));
    setOpenLanguagePopup(false);
    dispatch(languageActions.selectTranslation({ language: language }));
  };

  // display POST success & error
  useEffect(() => {
    if (languageReducer.status.TRANSLATION_POST === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("TRANSLATION_POST"));
        setErrorMessage(false);
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.TRANSLATION_POST)) {
      setErrorMessage(languageReducer.status.TRANSLATION_POST);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("TRANSLATION_POST"));
        setOpenErrorSnackbar(false);

        setErrorMessage(false);
      }, 4000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // display DELETE success & error
  useEffect(() => {
    if (languageReducer.status.TRANSLATION_DELETE === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);

      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("TRANSLATION_DELETE"));
        setErrorMessage(false);
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.TRANSLATION_DELETE)) {
      setErrorMessage(languageReducer.status.TRANSLATION_DELETE);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("TRANSLATION_DELETE"));
        setOpenErrorSnackbar(false);
        setErrorMessage(false);
      }, 4000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // display PUT success & error
  // login text
  useEffect(() => {
    if (languageReducer.status.LOGIN_TEXT_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("LOGIN_TEXT_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.LOGIN_TEXT_PUT)) {
      setErrorMessage(languageReducer.status.LOGIN_TEXT_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("LOGIN_TEXT_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // case overview text
  useEffect(() => {
    if (languageReducer.status.CASE_OVERVIEW_TEXT_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("CASE_OVERVIEW_TEXT_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.CASE_OVERVIEW_TEXT_PUT)) {
      setErrorMessage(languageReducer.status.CASE_OVERVIEW_TEXT_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("CASE_OVERVIEW_TEXT_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // about us text
  useEffect(() => {
    if (languageReducer.status.ABOUT_US_TEXT_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("ABOUT_US_TEXT_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.ABOUT_US_TEXT_PUT)) {
      setErrorMessage(languageReducer.status.ABOUT_US_TEXT_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("ABOUT_US_TEXT_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // faq
  useEffect(() => {
    if (languageReducer.status.FAQ_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("FAQ_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.FAQ_PUT)) {
      setErrorMessage(languageReducer.status.FAQ_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("FAQ_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // default translation
  useEffect(() => {
    if (languageReducer.status.DEFAULT_TRANSLATION_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("DEFAULT_TRANSLATION_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.DEFAULT_TRANSLATION_PUT)) {
      setErrorMessage(languageReducer.status.DEFAULT_TRANSLATION_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(languageActions.clearStatusMessage("DEFAULT_TRANSLATION_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // activate
  useEffect(() => {
    if (languageReducer.status.ACTIVATE_TRANSLATION_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(
          languageActions.clearStatusMessage("ACTIVATE_TRANSLATION_PUT")
        );
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.ACTIVATE_TRANSLATION_PUT)) {
      setErrorMessage(languageReducer.status.ACTIVATE_TRANSLATION_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(
          languageActions.clearStatusMessage("ACTIVATE_TRANSLATION_PUT")
        );
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  // deactivate
  useEffect(() => {
    if (languageReducer.status.DEACTIVATE_TRANSLATION_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(
          languageActions.clearStatusMessage("DEACTIVATE_TRANSLATION_PUT")
        );
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(languageReducer.status.DEACTIVATE_TRANSLATION_PUT)) {
      setErrorMessage(languageReducer.status.DEACTIVATE_TRANSLATION_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(
          languageActions.clearStatusMessage("DEACTIVATE_TRANSLATION_PUT")
        );
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, languageReducer.status]);

  useEffect(() => {
    if (
      errorMessage ===
      "Invalid request: translation has to be complete before activation."
    ) {
      setErrorMessage(
        "All required fields have to be present before activating a translation."
      );
    } else if (errorMessage === "Missing required property: content") {
      setErrorMessage(
        "This field cannot be empty when your translation is active!"
      );
    } else if (
      errorMessage ===
      "Invalid request: cannot deactivate assigned translations."
    ) {
      setErrorMessage(
        "You cannot deactivate a translation that is assigned to a case!"
      );
    } else if (
      errorMessage ===
      "Invalid request: cannot deactivate default translations."
    ) {
      setErrorMessage("You cannot deactivate your default translation!");
    } else if (
      errorMessage ===
      "Invalid request: cannot delete an activated translation."
    ) {
      setErrorMessage("You cannot delete an activated translation.");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (languageReducer.status.TRANSLATION_POST === "success") {
      dispatch(languageActions.getTranslations());
    }
  }, [languageReducer.status.TRANSLATION_POST, dispatch]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={styles.languages_container}>
        <div className={styles.language_list_container}>
          <Scrollbar
            options={{ suppressScrollX: true }}
            maxHeight="calc(100vh - 140px)"
          >
            {" "}
            <div className={styles.add_language_container}>
              <div className={styles.edit_icon_container}>
                <Button
                  className={styles.btn_add_language}
                  onClick={handleOpenLanguagePopup}
                  ref={anchorRef}
                  aria-controls={
                    openLanguagePopup ? "menu-list-grow" : undefined
                  }
                  aria-haspopup="true"
                >
                  <b className={styles.plus_sign}>+</b>Add Translation
                </Button>
                <Popper
                  open={openLanguagePopup}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={styles.edit_popup}
                >
                  <div>
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseLanguagePopup}>
                        <MenuList
                          autoFocusItem={openLanguagePopup}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {languageReducer.languages &&
                            languageReducer.languages.map(
                              (language) =>
                                languageReducer.translations?.translations &&
                                !languageReducer.translations.translations.some(
                                  (trans) => trans.language === language
                                ) && (
                                  <MenuItem
                                    onClick={() =>
                                      handleSelectLanguage(language)
                                    }
                                    key={language}
                                  >
                                    <b> {language.toUpperCase()}</b>
                                  </MenuItem>
                                )
                            )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </div>
                </Popper>
              </div>
            </div>
            {languageReducer.translations?.translations &&
              languageReducer.translations.translations.map((translation) => (
                <TranslationRow
                  translation={translation}
                  key={translation.id}
                />
              ))}
          </Scrollbar>
        </div>
        <div className={styles.tabs_and_content_container}>
          {languageReducer.selected_translation ? (
            <SettlementPortalTexts />
          ) : (
            <div className={styles.translation_no_data}>
              NO TRANSLATION IS SELECTED!
            </div>
          )}
        </div>
      </div>
      {displayUpgrade && (
        <UpgradePlanpopup
          title={title}
          subtitle={subtitle}
          imageUrl={UpgradeImage}
          copy_1={copy_1}
          upgradeButton={upgradeButton}
          open={displayUpgrade}
          close={() => setDisplayUpgrade(false)}
        />
      )}
    </div>
  );
}

export default Languages;
